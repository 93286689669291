const i18nConfig = {
  useBrowserLanguageDetection: false,
  legacy: false,
  locale: 'en-US',
  warnHtmlMessage: false,
};

export default defineI18nConfig(() => i18nConfig);

export { i18nConfig };
